import React from 'react'
import Layout from '../components/layout'
import PageStructure from '../components/pagestructure'
import SEO from '../components/seo'
import IMGAbout from '../images/new/2.jpg'
import { Link } from 'gatsby'
import Logo from '../images/logo.png'
import Grid from '@material-ui/core'

const content = {}
const Structure = () => {
  return (
    <div>
      <Layout>
        <SEO title="About US" />
        <PageStructure
          title="About Us"
          desc="TESTIG"
          mode="aboutus"
          image={IMGAbout}
          content={`
          
          <b style="font-family:'Volkhov';">Indian Revenue Service</b>
          <br/><br/>
          The Indian Revenue Service (Bhāratīya Rājasva Sevā), abbreviated to IRS, is the administrative revenue service of the Government of India. A Central Service, it functions under the Department of Revenue of the Ministry of Finance. The IRS is controlled by the Central Board of Direct Taxes (CBDT).
          <br/><br/>
          The IRS is primarily responsible for collecting and administering direct taxes accruing to the Government of India. The duties of the IRS include providing tax assistance to taxpayers, pursuing and resolving instances of erroneous or fraudulent tax filings, and formulating and enforcing policy concerning income tax in India. The Service is also the premier service which is a repository of Financial Administration in the Government of India. The officers of the Service are also deployed on loan basis or deputation basis in different public policy formulating positions in the Government of India. The IRS officers are deployed as Deputy Secretaries, Directors, Joint Secretaries, Additional Secretaries, Special Secretaries and Secretaries in the Government of India on deputation basis. IRS officers are eligible to be selected as a member of the Income Tax Appellate Tribunal, Authority for Advance Ruling, Income Tax Settlement Commission and Income Tax Ombudsman as per the prescribed eligibility criteria. They are also deployed as heads, CVOs and in other capacities with Attached Offices, Subordinate Offices, Autonomous Bodies and PSEs under the Government of India and in the State Governments.
          <br/><br/>
          The IRS processes around 6,00,00,000 returns and collects ₹12-13 lakh crores (equivalent to ₹12-13 trillion or US$ 200 billion) in gross revenue, spending only ₹6 (equivalent to 9.8¢ US) for every ₹1,000 (equivalent to US$17) collected. The relative contribution of direct tax to the overall tax collection of the Central Government is around 56%. The contribution of direct tax-to-GDP is about 6%.
          <br/><br/>
          Direct tax, in the form of income tax, was introduced by the British in India in 1860 to overcome the difficulties created by the Indian Rebellion of 1857. The organizational history of the Income-tax Department, however, starts in the year 1922, when the Income-tax Act, 1922 gave, for the first time, a specific nomenclature to various Income-tax authorities. In 1924, the Central Board of Revenue Act constituted a Central Board of Revenue - the statutory body with functional responsibilities for the administration of the Income-Tax Act. 
          <br/><br/>
          Commissioners of income tax were appointed for each province and assistant commissioners and tax officers were placed under their control. Officers from the Imperial Civil Services (ICS) manned top posts and the lower echelons were filled through promotions from the ranks. The Income Tax Service was established in 1944, which was subsequently re-constituted as the Indian Revenue Service (Income Tax) in 1953. 
          <br/><br/>
          In 1963, given the increasingly complex roles and responsibilities of administering direct tax in India, the Central Board of Direct Taxes was constituted as a statutory body under the Central Board of Revenue Act, 1963. 
          <br/><br/>
          <b style="font-family:'Volkhov';">Recruitment</b>
          <br/><br/>
          There are two streams of recruitment to the Indian Revenue Service. IRS officers may enter into the IRS by passing the Civil Services Examination (CSE). The CSE is a three-stage competitive selection process consisting of a preliminary examination, a main examination, and an interview. It is administered by the Union Public Service Commission (UPSC). IRS officers recruited in this way are called direct recruits. 
          <br/><br/>
          Some IRS officers are also recruited from the Income Tax Service (Group B). Group 'B' officers are gradually promoted over several years of service. The current ratio of two streams at the entry level is kept 1:1. All IRS officers, regardless of their mode of entry, are appointed by the President of India. 
          <br/><br/>
          <b style="font-family:'Volkhov';">Training</b>
          <br/><br/>
          After selection, successful candidates undergo a 3-month Foundation Course at the Lal Bahadur Shastri National Academy of Administration (LBSNAA) in Mussoorie, Uttarakhand. Thereafter, IRS Officer Trainees (OTs) undergo a 16-month specialized training at the National Academy of Direct Taxes (NADT), in Nagpur, Maharashtra. 
          <br/><br/>
          <b style="font-family:'Volkhov';">National Academy of Direct Taxes</b>
          <br/><br/>
          Sixteen months of Induction Training is conducted for the directly recruited officers of IRS at NADT each year. The training is designed into two modules to enable the Officer Trainees to acquire the knowledge and skills they need to function effectively and efficiently as tax administrators. In particular, training prepares OTs to deliver quality taxpayer services, detect and penalize non-compliance, and understand the macroeconomic, taxation and fiscal policy of the Government of India to optimize revenue.
          <br/><br/>
          <b style="font-family:'Volkhov';">First Module</b>
          <br/><br/>
          The First Module emphasizes on giving intense theoretical inputs in the subjects such as Theoretical Concepts and Practical Application of Direct Tax Laws, Advanced Accounting and Finance, and Business Laws-I. It also includes a week-long Parliamentary Attachment, and a two-week Field Attachment. Officer Trainees also undergo short-duration training at NACIN, Faridabad, LBSNAA, Mussoorie, and the Sardar Vallabhbhai Patel National Police Academy (SVPNPA), Hyderabad. The first Departmental Examinations are conducted at the end of the first module.
          <br/><br/>
          <b style="font-family:'Volkhov';">Second Module</b>
          <br/><br/>
          The second module includes On-The-Job Training for around eight weeks, followed by Bharat Darshan, and a Financial Attachment for 2 weeks. Further, The OTs are also exposed to the international tax practices through a one or two weeks International Attachment in France, Netherlands, Australia, Malaysia, South Africa, the US, or Singapore. OTs are also trained to apply theoretical concepts and acquire practical skills through the subjects of Procedure/Techniques of Investigation and Drafting of Orders and Reports, Information Technology and Operations, Management and Administration in the Income Tax department, International Taxation and Transfer Pricing, Law of Governance and Ethics, and Business Laws-II. A number of guest lectures are organized to familiarize the trainees with the best practices in the tax administration. 
          <br/><br/>
          NADT has also signed a memorandum of understanding with the National Law School of India University in Bengaluru to award postgraduate diploma in business laws to the officer trainees. Recently, Finance Ministry has approved exchange of officer trainees to various countries across the world such as Netherlands, Belgium, Switzerland, France, Australia, Malaysia, United States of America, Brazil, South Africa and OECD Nations for increasing exposure to the future administrators.
          <br/><br/>
          Officer Trainees are expected to complete a project on Direct Tax Provisions for the award of a Diploma in Business Law and Taxation from National Law School University of India, Bengaluru at the time of Valediction from NADT. 
          <br/><br/>
          There are various mid-career training programmes (MCTP) for IRS officers with a view to keep them abreast of the latest developments in the areas of governance, particularly taxation, finance and management. These include the advanced mid career training (AMCTP) which is conducted in 3 phases. Phase I is for joint and additional commissioners, Phase-II is for commissioners and principal commissioners and Phase-III is for chief commissioners and principal chief commissioners. The AMCTP generally consists of a 3-week domestic module held at premier management institutes in the country followed by a 2-week international component at internationally acclaimed universities, depending on their areas of expertise.
          <br/><br/> 
          <b style="font-family:'Volkhov';">Designations</b>
          <br/><br/>
          The designations and time-scales within the Indian Revenue Service are as follows after cadre restructure
          <br/><br/>
          <table border="1">
            <thead>
              <tr>
                <th>Position / Pay Grade in the Government of India</th>  
                <th>Designations</th>  
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Junior Time Scale</td>
                <td>Assistant Commissioner of Income Tax/Assistant Director of Income Tax</td>
              </tr>
              <tr>
                <td>Senior Time Scale</td>
                <td>Deputy Commissioner of Income Tax/Deputy Director of Income Tax</td>
              </tr>
              <tr>
                <td>Junior Administrative Grade</td>
                <td>Joint Commissioner of Income Tax/Joint Director of Income Tax</td>
              </tr>
              <tr>
                <td>Selection Grade</td>
                <td>Additional Commissioner of Income Tax/Additional Director of Income Tax</td>
              </tr>
              <tr>
                <td>Senior Administrative Grade </td>
                <td>Commissioner of Income Tax/Director of Income Tax and Additional Director General</td>
              </tr>
              <tr>
                <td>Higher Administrative Grade</td>
                <td>Principal Commissioner of Income Tax</td>
              </tr>
              <tr>
                <td>Higher Administrative Grade +</td>
                <td>Chief Commissioner of Income Tax and Director General</td>
              </tr>
              <tr>
                <td>Apex Scale</td>
                <td>Principal Chief Commissioner of Income Tax, Principal Director General, CBDT Chairperson, Member of CBDT, Director General of the Central Economic Intelligence Bureau</td>
              </tr>
            </tbody>
          </table>
          <br/><br/>
          <b style="font-family:'Volkhov';">Recent initiatives</b>
          <br/><br/>
          Many new initiatives were taken by the Indian Revenue Service members to make the system more efficient and responsive to the needs of the tax payers. Use of Technology, Refund Banker scheme introduced in 2007, Introduction of E-filing of Taxes and effective implementation of Permanent Account Number (PAN), Use of Centralized Processing Center setup in 2010 at Bangalore of the Income Tax Department are some revolutionary steps. 
          <br/><br/>
          Income Tax Ombudsmen has been created in 2006 and is functional at 12 cities to look into tax related grievances of the public. Department is also gearing to improve its tax payer's services with Sevottam Scheme. Under this scheme various initiatives such as Citizen Charter, Ayakar Seva Kendra (ASK) which is a single Window mechanism for implementing Sevottam through delivery of these services within the time lines promised in the Citizen's Charter were launched. Aaykar Sampark Kendra consists of one National Call Centre and 4 regional Call Centres to aid the taxpayer were inaugurated by the Finance Minister. 
          <br/><br/>
          An IRS officer could rise up-to the Apex Scale at the post of Principal Chief Commissioner of Income Tax in the ITD. At the apex level, he can also get selected as a Member or Chairperson of the CBDT. The intermediate grades in this career progression are deputy commissioner, joint commissioner, additional commissioner, commissioner, principal commissioner and chief commissioner of income tax. An IRS officer is also eligible to be selected as a member of the Income Tax Appellate Tribunal, Authority for Advance Ruling, Income Tax Settlement Commission and Income Tax Ombudsman as per the prescribed eligibility criteria. The IRS officers are also deployed as Deputy Secretaries, Directors, Joint Secretaries, Additional Secretaries, Special Secretaries and Secretaries in the Government of India on deputation basis. They are also deployed as heads, CVOs and in other capacities with Attached Offices, Subordinate Offices, Autonomous Bodies and PSEs under the Government of India.
          `}
        />
      </Layout>
    </div>
  )
}

export default Structure
